<template>
  <main id="framework">
    <section id="page-title">
      <a @click="$router.go(-1)"><img src="../assets/FrameworkBtn.svg" class="framework-btn"></a>
      <row>
        <column :lg="8" class="page-title">
          <div align="left" class="back">
            <a @click="$router.go(-1)" class="btn">
              <div><img src="../../src/assets/BackArrow.svg" alt="back-arrow"/> Back</div>
            </a>
          </div>

           <!-- <div
                classhref="#"
                tabindex="0"
                class="popover-style"
              >
                <Bpopover1 :info="info1" idPopover="pb1" />
              </div> -->

          <h1 class="title">Policy in practice - Low income households’ savings</h1>
        </column>
        <column :lg="4" class="progress-summary">

        <!-- <div
              classhref="#"
              tabindex="0"
              title=""
              class="popover-style"
              style="position: absolute; right: 5px !important; top: -14px"
            >
              <Bpopover2 :info="info2" idPopover="2PB" id="2pbC" />
            </div> -->


        
          <div class="doughnut-1"><aim-doughnut-chart :doughnutChartData="doughnutChartData1"></aim-doughnut-chart></div>
          <div class="doughnut-2"><time-doughnut-chart :doughnutChartData="doughnutChartData2"></time-doughnut-chart></div>
        </column>
      </row>
    </section>
    <section class="framework-select-area">
      <row :gutter="12" >
        <column :lg="1"><h3 class="framework-select-country">Select type</h3></column>
        <column :lg="2.5" class="framework-select-box">
          <v-select :options="moneyTypes" placeholder="Show all" :searchable="false">
          </v-select>
        </column>
      </row>
    </section>
    <section :gutter="12" class="chart-title-area">
      <row class="chart-title">
        <column :lg="8" :xs="6"><h2 class="framework-sub-title">Low income households’ savings - across funds</h2></column>
        <column class="chart-summary" :lg="4" :xs="6">
          <div class="total-money"> <span><h1>£{{ totalTenants }} saved</h1></span> <h3 class="source-sans-font">by Resonance’s tenants</h3></div>
          <div class="growth-rate"> <h1>{{growthRate}}</h1> <h3 class="source-sans-font">in 2020</h3></div>
        </column>
      </row>
    </section>
    <section id="chart-area">
      <row :gutter="12" class="chart-main">
        <column :lg="6" class="douhnut-chart-area">
        <h2>Achieved - £10,000 saved by Resonance tenants</h2>
          <framework-doughnut-chart :doughnutChartData="achievedDoughnutChartData"></framework-doughnut-chart>
          <column :lg="4" :xs="12" class="year-select-box" >in <v-select :options="yearOptions" v-model="selectedYear" class="select-year" placeholder="Show all" :searchable="false"></v-select></column>
        </column>
        <column :lg="6" class="douhnut-chart-area">
        <h2>Aim - £60,000 savings aim by Resonance tenants</h2>
          <framework-doughnut-chart :doughnutChartData="aimDoughnutChartData"></framework-doughnut-chart>
          <column :lg="4" :xs="12" class="year-select-box" >in <v-select :options="yearOptions" v-model="selectedYear" class="select-year" placeholder="Show all" :searchable="false"></v-select></column>
        </column>
      </row>
    </section>
  </main>
</template>

<script>
import AimDoughnutChart from '../components/Chart/AimDoughnutChart.vue'
import TimeDoughnutChart from '../components/Chart/TimeDoughnutChart.vue'
import FrameworkDoughnutChart from '../components/Chart/FrameworkDoughnutChart'
import { setYearSelectBox } from '../data/data-provider.js'
// import Bpopover1 from "../components/B-popover.vue";
// import Bpopover2 from "../components/B-popover.vue";



export default {
  components: {
    AimDoughnutChart,
    TimeDoughnutChart,
    //  Bpopover1,
    //  Bpopover2,
    FrameworkDoughnutChart
  },
  data () {
    return {
      selectedYear: 2020,
        info1: `In this demo project the social enterprise offers digital education to students in refugee camps in 6 african countries. Measuring the programme’s success against SDG 4 -Quality Education- we present how many refugee students were helped into school, out of the total number of refugee children out of school, worldwide. `,

        info2: `If you run more than one programmes as 
              a fund, foundation, charity or sustainable 
              corporation, you can navigate through the 
              dashboards of each programme, from this 
              drop down selection.`,

      doughnutChartData1: {
        cssId: 'box1',
        title: 'Aim',
        subtitle1: '£50K',
        subtitle2: '/year',
        subtitle2Position: [36, 36.2, 40],
        subtitle3: 'by Resonance',
        percentage: '20',
        insideText: 'complete',
        color: '#8954BA'
      },
      doughnutChartData2: {
        box: 'box2',
        title: 'Time',
        subtitle1: '8 years',
        subtitle2: '',
        percentage: '5',
        insideText: 'more year',
        color: '#0091FF',
        totalYear: 8
      },
      achievedDoughnutChartData: {
        canvasId:'achieved',
        firstlinebold: '£60K',
        firstlinelight: 'saved by UK households',
        firstlinePosition: [6, 11, 500, 45, 11, 500],
        secondlinebold: '£10K',
        secondlinelight: 'saved by Resonance tenants',
        secondlinePostiion: [7, 43, 500, 45, 43, 500],
        thirdlinebold: '£4K',
        thirdlinelight: 'spent by Resonance tenants',
        thirdlinePostiion: [15, 75, 500, 45, 75, 500],
        percentageForChart: [90, 20, 10], // out / middle / inside
        percentage: '16',
        percentageTextPostiion: [180, 160, 500],
        insideText: 'contribution',
        insideTextPostiion: [200, 190, 100],
        centerX: 250,
        centerY: 163.5
      }, 
      aimDoughnutChartData: {
        canvasId:'aim',
        firstlinebold: '£200K',
        firstlinelight: 'savings aim by UK households',
        firstlinePosition: [2, 11, 500, 50, 11, 500],
        secondlinebold: '£60K',
        secondlinelight: 'savings aim by Resonance tenants',
        secondlinePostiion: [10, 43, 500, 50, 43, 500],
        thirdlinebold: '£34K',
        thirdlinelight: 'spending aim by Resonance tenants',
        thirdlinePostiion: [10, 75, 500, 50, 75, 500],
        percentageForChart: [60, 30, 40], // out / middle / inside
        percentage: '30',
        percentageTextPostiion: [250, 160, 500],        
        insideText: 'contribution',
        insideTextPostiion: [255, 190, 100],
        centerX: 291.66,
        centerY: 163.5
      },            
      yearOptions: [],
      moneyTypes: [
        'Money offered to tenants',
        'Money saved by tenants',
        'Money spent by tenants'
      ],
      totalTenants: '10,000',
      growthRate: '+56%'
    }
  },
  mounted () {
    this.showNavBar()
    this.yearOptions = setYearSelectBox() // Set initial Year select box options
  },
  methods: {
    showNavBar () {
      const navbar = document.getElementById('nav')
      navbar.style.display = 'inline'
    }
  }
}
</script>

<style src="@/assets/styles/views/Framework.css"></style>
